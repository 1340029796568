import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout>
    <SEO title="Page not found | React Freelancer Switzerland" />
    <header className="masthead d-flex">
      <div className="container text-center my-auto">
        <h1 className="text-dark mb-3">Page not found</h1>
        <p className="mb-5">Whoops, something went wrong!</p>

        <Link
          className="btn btn-primary btn-xl"
          to="/"
          title="Home page of this website"
        >
          Return to the main page
        </Link>
      </div>
      <div className="overlay"></div>
    </header>
  </Layout>
);

export default IndexPage;
